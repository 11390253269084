import React from 'react'
import SelectPaginated from 'select-paginated';

function Test() {
  return (
    <div>
    <SelectPaginated 
        pageSize      = {100}
        isLinearArray = {false}
        api = {{
            resourceUrl   : "https://jsonplaceholder.typicode.com/comments",
            pageParamKey  : "_page", 
            limitParamKey : "_limit",
           
        }}
        onSelect={(selectedItems) => {
            console.log('selected items :: ', JSON.stringify(selectedItems));
        }}
        onRemove={(removedItem) => {
            console.log('Removed items :: ', JSON.stringify(removedItem));
        }}
        multiSelect       = {true}
        searchPlaceholder = "Search..."
        displayKey        = "name"
        localStorageKey   = "SelectFetchedData"
    /></div>
  )
}

export default Test